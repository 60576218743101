/* .MuiAppBar-colorPrimary {
  background-color: antiquewhite;
} */

  .Scroll {
    height:600px;
    overflow:auto;
  }
  .Scroll::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .Scroll::-webkit-scrollbar-track {
    background: #FFFBF6; 
  }
   
  /* Handle */
  .Scroll::-webkit-scrollbar-thumb {
    background: #C4C4C4; 
    border-radius:4px
  }

  .swiper{
    height:600px;
    overflow:auto;
  }
  .swiper::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .swiper::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  .swiper::-webkit-scrollbar-thumb {
    background: #C4C4C4; 
    border-radius:4px
  }

  .InputPlaceholder::placeholder{
    color:'white'
  }
  .parentclassname input[type='password']::-ms-reveal,
.parentclassname input[type='password']::-ms-clear {
  display: none;
}
  
.monaco-editor, .monaco-editor-background, .monaco-editor .inputarea.ime-input {
  border-radius: 4px;
  padding: 4px 0px 4px 0px;
}

/*invite toast style*/
.Toastsuccess{
  position: absolute;
  top:17px
}
.invitemsg{
  margin-left:25px
}


#no-copy {
  user-select: none;
}
#no-copy::selection {
  background: none;
}
#no-copy::-moz-selection {
  background: none;
}