.Home-hero {
    align-items: center;
    height: 100%;
    width: 100%;
}



.stepImgMob{
    margin: auto;
    display: block;
    border-radius: 160px;
    margin-bottom: 34px;
    width: 160px;
}

.stepImg {
    margin: auto;
    display: block;
    border-radius: 160px;
    width: 184px;
    /* margin-bottom: 24px; */
}
.ellipse{
    margin-right: 8%;
}
.how-long-main h3{
    margin-bottom:32px;
}

.how-long-main-mob .MuiGrid-item{
    /* margin-left:8px; */
}

.align-right {
    justify-items: right;
}
